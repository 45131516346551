import React, { Fragment, useEffect, useState } from "react"
import axios from "axios"

import Loading from "../Elements/Loading"
import { navigate } from "gatsby"
import { isBrowser } from "../../services/general"
import {
  hasRoles,
  hasSignedInUser,
  hasVerifiedUser,
  hasVerifiedName,
} from "../Auth/services/user"

import {
  GATSBY_ORDER_AS_GUEST,
  GATSBY_ORDER_AS_GUEST_KEY,
  GATSBY_ORDER_AS_GUEST_VALUE,
  GATSBY_GET_ORDER_DETAILS_WEBHOOK,
} from "gatsby-env-variables"

const PrivateRoute = ({ 
  isPrivate, 
  isVerifyNamePage,
  locationState,
  children 
}) => {
  let isOrderingAsGuest = false

  if (GATSBY_ORDER_AS_GUEST === "enabled" && isBrowser()) {
    isOrderingAsGuest =
      window.sessionStorage.getItem(GATSBY_ORDER_AS_GUEST_KEY) ===
      GATSBY_ORDER_AS_GUEST_VALUE
  }

  const [loading, setLoading] = useState(!!isVerifyNamePage) 

  const navigateToVerifyName = async () => {
    const { zendeskId } = locationState
    const getOrderDetails = async () => {
      let response = await axios.post(GATSBY_GET_ORDER_DETAILS_WEBHOOK, {
        zendeskId,
      })
      locationState = { ...locationState, lastName: response.data.lastName }
    }
    if (!!zendeskId) await getOrderDetails()
    locationState.lastName ? navigate("/verify-name", { state: locationState }) : navigate("/proof-of-payment/invalid")
  }
  useEffect(() => {
    if (!isPrivate && isBrowser()) {
      if (isVerifyNamePage && locationState.zendeskId && !hasVerifiedName()) {
        setLoading(true)
        navigateToVerifyName()
      }
      if (isVerifyNamePage && hasVerifiedName()) {
        setLoading(false)
      }
    }
  }, [])

  if (isPrivate && isBrowser()) {
    // if signed-in, but not enrolled, navigate to enrollment
    if (hasSignedInUser() && !hasRoles() && !isOrderingAsGuest)
      navigate("/patient/enroll")
    // if (!hasSignedInUser() && !isOrderingAsGuest) {
    //   // navigate to sign in page
    //   locationState ? navigate("/sign-in", { state: locationState }) : navigate("/sign-in")
    //   return <Loading />
    // }
    if (isVerifyNamePage && locationState.zendeskId && !hasVerifiedName()) {
      setLoading(true)
      navigateToVerifyName()
    }
    if (isVerifyNamePage && hasVerifiedName()) {
      setLoading(false)
    }
    // if (hasRoles() && !hasVerifiedUser() && !isOrderingAsGuest) {
    //   navigate("/verify-birthday")
    //   return <Loading />
    // }
  }

  return <Fragment>{children}</Fragment>
}

export default PrivateRoute
